import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    users: [],
    teams: [],
    designationModalShow:false,
    defaultUserDesignation:'',
    defaultUserCycle:'',
    Id:'',
    workSummaryModal:false,
    rowData:{},
    modalType:'',
    page:1,
    pageSize:30,
    refreshEffect:0,
    meta:{},
    cycleList:{},
    designationList:{},
    statusList:{},
    cycleFilter:'',
    searchText:'',
    appraisalFilter:null,
    designationFilter:'',
    formStatusFilter:'',
    sorting:'',
    emailSort:''
}
  
const adminSlice = createSlice({
  name: "admin",
  initialState, 
  reducers: {
    updateUsers(state = initialState, action) {
      state.users = [...action.payload.users];
    },

    updateSingleUser(state = initialState, action) {
      const newUsers = state.users.map((user) => {
        if (user.id === action.payload.user.id) {
          user = action.payload.user;
        }
        return user;
      });

      state.users = [...newUsers];
    },
    updateTeams(state = initialState, action) {
      state.teams = [...action.payload];
    },
    updateDesignationModal(state=initialState,action){
      state.designationModalShow = action.payload
    },
    updateModalType(state=initialState,action){
      state.modalType = action.payload
    },
    updateWorkSummaryModal(state=initialState,action){
      state.workSummaryModal= action.payload
    },
    updateMeta(state=initialState,action){
      state.meta = {...action.payload}
    },
    updatePage(state=initialState,action){
      state.page = action.payload.page
      state.pageSize = action.payload.pageSize
    },
    updateRefreshEffect(state=initialState,action){
      state.refreshEffect = action.payload
    },
    updateCycle(state=initialState,action){
      state.cycleList = {...action.payload}
    },
    updateDesignation(state=initialState,action){
      state.designationList = {...action.payload}
    },
    updateStatus(state=initialState,action){
      state.statusList = {...action.payload}
    },
    updateUser_Cycle_Designation(state=initialState,action){
      state.Id = action.payload.Id
      state.defaultUserCycle = action.payload.defaultCycle
      state.defaultUserDesignation = action.payload.defaultLevel
    },
    updateRowData(state=initialState,action){
      state.rowData = {...action.payload}
    },
    updateFilter(state=initialState,action){
      state.appraisalFilter= action.payload.appraisalFilter
      state.designationFilter = action.payload.designationFilter
      state.cycleFilter = action.payload.cycleFilter
      state.formStatusFilter = action.payload.formStatusFilter
    },
    updateSortFilter(state=initialState,action){
      state.sorting = action.payload.sortByName
      state.emailSort = action.payload.sortByEmail
    },
    updateSearchText(state=initialState, action){
      state.searchText = action.payload
    }
  },
});

export default adminSlice.reducer;

export const adminActions = adminSlice.actions;