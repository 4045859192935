import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "../../index.css";
import { Row, Col, Table, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { managerActions } from "./store";
import Api from "services/Api";
import Boxes from "./boxes";
import { useHistory } from "react-router-dom";
import { globalActions } from "store/loader";

const Manager = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { managerTeam } = useSelector((state) => state.manager);
  const { loader } = useSelector((state) => state.global);
  const history = useHistory();

  console.log("Entered in Manager")

  useEffect(() => {
    dispatch(globalActions.updateLoader({ loader: true }));
    Api.get("/manager/teams?managerId=" + user.id)
      .params({})
      .send((response, error) => {
        if (typeof response != "undefined") {
          dispatch(
            managerActions.updateManagerTeam({ managerTeam: response.teams })
          );
        }
      });

    return () => {
      // dispatch(managerActions.updateSelectedTeamId({ selectedTeamId: null }));
    };
  }, []);

  const onRankClick = (row) => {
    dispatch(managerActions.updateSelectedTeamId({ selectedTeamId: row.id }));
    history.push("/manager/associate-rank");
  };
  const onPerformanceClick = (row) => {
    dispatch(managerActions.updateSelectedTeamId({ selectedTeamId: row.id }));
    history.push("/manager/performance-matrix");
  };
  const onFeedbackClick = (row) => {
    dispatch(managerActions.updateSelectedTeamId({ selectedTeamId: row.id }));
    history.push("/manager/associate-list");
  };

  const dataSource = managerTeam.map((team) => {
    return {
      key: team.teamId,
      id: team.teamId,
      name: team.teamName,
      workSummary: team.workSummary,
      teamRanked: team.teamRanked,
      performanceAttitudeRanked: team.performanceAttitudeRanked,
      feedbackCompleted: team.feedbackCompleted,
    };
  });

  const columns = [
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      render: (val, row) => (
        <Button
          type="primary"
          onClick={() => onRankClick(row)}
          disabled={!row.teamRanked}
        >
          Rank Team
        </Button>
      ),
    },
    {
      title: "Performance",
      dataIndex: "performance",
      key: "performance",
      render: (val, row) => (
        <Button
          type="primary"
          onClick={() => onPerformanceClick(row)}
          disabled={!row.performanceAttitudeRanked}
        >
          Performance Attitude
        </Button>
      ),
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
      key: "feedback",
      render: (val, row) => (
        <Button
          type="primary"
          onClick={() => onFeedbackClick(row)}
          disabled={!row.feedbackCompleted}
        >
          Submit Feedback
        </Button>
      ),
    },
  ];
  if (loader) {
    return null;
  } else {
    return (
      <>
        <div className="contained">
          {/* <div style={{ paddingLeft: "20px" }}> */}{" "}
          <h2>Manager Name: {user.name} </h2>
          <hr className="solid"></hr>
          {/* </div> */}
          {/* <Row justify="start" align="top" gutter={[32, 40]}>
          {teamList}
        </Row> */}
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </div>
      </>
    );
  }
};

export default Manager;
