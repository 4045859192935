import { Button, Table, Select, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import Api from "services/Api";
import { adminActions } from "./store";
import WorkSummaryModal from "./work-summary-modal";
import refreshIcon from "../../assets/refresh-cw.svg";
import CommonModal from "./commonModal";
import { AiFillFilter, AiFillCaretUp, AiFillCaretDown } from "services/Icons";
import { Color } from "services/color";
import classes from "../styles/userTable.module.css";

const UserTable = (props) => {
  const {
    users,
    meta,
    designationList,
    cycleList,
    statusList,
    appraisalFilter,
    designationFilter,
    cycleFilter,
    formStatusFilter,
    sorting,
    page,
  } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const history = useHistory();
  const Option = Select.Option;

  const [windowHeight, setWindowHeight] = useState(window.screen.height);

  const [initialFilter, setInitialFilter] = useState({
    appraisalFilter: appraisalFilter,
    designationFilter: designationFilter,
    cycleFilter: cycleFilter,
    formStatusFilter: formStatusFilter,
  });

  useEffect(() => {
    dispatch(adminActions.updateFilter(initialFilter));
    dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
    // console.log("Window Size", windowHeight);
  }, [initialFilter, windowHeight, page]);

  useEffect(() => {
    if (history.action === "POP" || history.action === "PUSH") {
      dispatch(adminActions.updateSearchText(""));
    }
  }, [history]);

  const handlePageChange = (page, pageSize, value) => {
    console.log("ppp", page, pageSize, value);
    dispatch(adminActions.updatePage({ page: page, pageSize: pageSize }));
    dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
  };

  const handleAppraisalfilter = (value) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    setInitialFilter({
      ...initialFilter,
      appraisalFilter:
        value === undefined ? "" : value === "Active" ? true : false,
    });
  };
  const handleCycleFilter = (value) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    setInitialFilter({ ...initialFilter, cycleFilter: value });
  };
  const handleDesignationFilter = (value) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    setInitialFilter({ ...initialFilter, designationFilter: value });
  };
  const handleFormStatusFilter = (value) => {
    dispatch(adminActions.updatePage({ page: 1, pageSize: 30 }));
    setInitialFilter({ ...initialFilter, formStatusFilter: value });
  };
  const handleSortByName = (value) => {
    value === sorting
      ? dispatch(
          adminActions.updateSortFilter({ ...{}, sortByName: undefined })
        )
      : dispatch(adminActions.updateSortFilter({ ...{}, sortByName: value }));
    dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
    console.log("Sorting Filter by name", value);
  };

  const openCommonModal = (row) => {
    console.log("Common Modal Data", row);
    // setAppraisalCycleOpen(true);
    dispatch(
      adminActions.updateUser_Cycle_Designation({
        Id: row?.id,
        defaultCycle: row?.appraisalCycle,
        defaultLevel: row?.associateLevel,
      })
    );
    dispatch(adminActions.updateDesignationModal(true));
  };

  const onAppraisalStatusClick = (data) => {
    const appraisalActive = data.appraisalActive ? false : true;
    Api.patch("/admin/users/?userId=" + data.id)
      .params({ appraisalActive })
      .send((response, error) => {
        if (response !== "undefined" && response?.show?.type === "success") {
          dispatch(adminActions.updateSingleUser({ user: response.data }));
          dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
        }
      });
  };

  const onWorkSummaryClick = (data) => {
    dispatch(adminActions.updateWorkSummaryModal(true));
    dispatch(adminActions.updateRowData(data));
    console.log("Row data for workSummary", data);
  };

  const appraisalOptions = {
    active: "Active",
    inactive: "Inactive",
  };

  const content = (
    <div>
      <h4
        style={{
          paddingTop: "0.5rem",
          color: "#696969",
        }}
      >
        Appraisal Cycle
      </h4>
    </div>
  );
  const content1 = (
    <div>
      <h4
        style={{
          paddingTop: "0.5rem",
          color: "#696969",
        }}
      >
        Appraisal Form Status
      </h4>
    </div>
  );
  const content2 = (
    <div>
      <h4
        style={{
          paddingTop: "0.5rem",
          color: "#696969",
        }}
      >
        Appraisal Status
      </h4>
    </div>
  );

  const sortAscending = <h4>Click to sort ascending </h4>;
  const sortDescending = <h4>Click to sort descending </h4>;

  const columns = [
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            width: "9rem",
          }}
        >
          <h4
            style={{
              fontSize: "1rem",
              paddingTop: "0.5rem",
              color: "#696969",
            }}
          >
            Associate
          </h4>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "1rem",
            }}
          >
            <Popover content={sortAscending}>
              <Button
                type="text"
                style={{ height: "0.8rem", margin: "0", padding: "0" }}
                onClick={() => handleSortByName("ASC")}
              >
                <AiFillCaretUp
                  style={{
                    color:
                      sorting === "" ||
                      sorting === null ||
                      sorting === undefined
                        ? "#787b83"
                        : sorting === "ASC"
                        ? Color.modalblue
                        : "#787b83",
                  }}
                />
              </Button>
            </Popover>
            <Popover content={sortDescending}>
              <Button
                type="text"
                style={{ height: "0.8rem", margin: "0", padding: "0" }}
                onClick={() => handleSortByName("DESC")}
              >
                {
                  <AiFillCaretDown
                    style={{
                      color:
                        sorting === "" ||
                        sorting === null ||
                        sorting === undefined
                          ? "#787b83"
                          : sorting === "DESC"
                          ? Color.modalblue
                          : "#787b83",
                    }}
                  />
                }
              </Button>
            </Popover>
          </div>
        </div>
      ),
      dataIndex: "associate",
      key: "associate",
      width: "16%",

      render: (val, row) => {
        return (
          <div
            style={{
              color: "black",
              width: "100%",
              whiteSpace: "nowrap",
            }}
          >
            <a
              onClick={() => {
                history.push({
                  pathname: "/associatesDetails",
                  state: row?.id,
                });
              }}
              type="link"
            >
              <Popover content={row?.name}>
                <h5
                  style={{
                    fontSize: "1rem",
                    width: "100%",
                    textOverflow: "ellipsis",
                    overflow: "hidden ",
                    cursor: "pointer",
                    color: "black",
                    margin: "0",
                  }}
                >
                  {row?.name}
                </h5>
              </Popover>
            </a>
            <h6 style={{ fontSize: "0.75rem", margin: "0" }}>
              {row?.designation}
            </h6>
            <h6 style={{ fontSize: "0.75rem", margin: "0" }}>
              {row?.employeeId}
            </h6>
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            width: "9rem",
          }}
        >
          <h4
            style={{
              fontSize: "1rem",
              paddingTop: "0.5rem",
              color: "#696969",
            }}
          >
            Contact Details
          </h4>
        </div>
      ),
      dataIndex: "contactDetails",
      key: "contactDetails",
      width: "13%",

      render: (val, row) => (
        <div
          style={{
            color: "black",
            width: "100%",
            whiteSpace: "nowrap",
          }}
        >
          <Popover content={row?.email}>
            <h5
              style={{
                fontSize: "1rem",
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden ",
                cursor: "pointer",
                color: "black",
                margin: "0",
              }}
            >
              {row?.email}
            </h5>
          </Popover>
          <span style={{ fontSize: "0.75rem" }}>{row?.mobile}</span>
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            width: "7rem",
            // border:'1px solid black'
          }}
        >
          <h4
            style={{
              paddingTop: "0.5rem",
              color: "#696969",
              fontSize: "1rem",
            }}
          >
            Work Summary
          </h4>
        </div>
      ),
      width: "13%",
      dataIndex: "workSummary",
      key: "workSummary",
      render: (val, row) => (
        <div style={{ width: "100%" }}>
          {
            <a
              type="text"
              style={{
                color: "black",
                fontSize: "1rem",
              }}
              onClick={() => onWorkSummaryClick(row)}
            >
              <PlusCircleOutlined style={{ marginRight: "0.3rem" }} />
              {val.length > 0 ? (
                <span>Change Summary</span>
              ) : (
                <span>Add Summary</span>
              )}
            </a>
          }
        </div>
      ),
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "space-between",
            gap: "0.5rem",
            width: "100%",
          }}
        >
          <Popover content={"Associate Level"}>
            <h4
              style={{
                margin: "0rem",
                color: "#696969",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "1rem",
              }}
            >
              Associate Level
            </h4>
          </Popover>
          <div>
            <Select
              suffixIcon={
                <AiFillFilter
                  style={{
                    color:
                      designationFilter === "" ||
                      designationFilter === null ||
                      designationFilter === undefined
                        ? "#787b83"
                        : Color.modalblue,
                  }}
                />
              }
              allowClear
              trigger="click"
              type="text"
              placement="bottomLeft"
              bordered={false}
              dropdownMatchSelectWidth={false}
              onChange={handleDesignationFilter}
              removeIcon={true}
            >
              {Object.values(designationList).map((item,index) => (
                <Option key={index} value={item} />
              ))}
            </Select>
          </div>
        </div>
      ),
      key: "designation",
      width: "14%",

      render: (val, row) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "8rem", fontSize: "1rem" }}>
              {row?.associateLevel}
            </div>
            <div style={{ marginLeft: "-1rem" }}>
              <a
                type="primary"
                onClick={() => {
                  dispatch(adminActions.updateModalType("Designation"));
                  openCommonModal(row);
                }}
              >
                <img src={refreshIcon} style={{ width: "1.2rem" }} alt="" />
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "space-between",
            gap: "0.5rem",
            width: "100%",
          }}
        >
          <Popover content={content}>
            <h4
              style={{
                margin: "0rem",
                color: "#696969",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "1rem",
              }}
            >
              Appraisal Cycle
            </h4>
          </Popover>
          <div>
            <Select
              suffixIcon={
                <AiFillFilter
                  style={{
                    color:
                      cycleFilter === "" ||
                      cycleFilter === null ||
                      cycleFilter === undefined
                        ? "#787b83"
                        : Color.modalblue,
                  }}
                />
              }
              allowClear
              trigger="click"
              type="text"
              placement="bottomLeft"
              bordered={false}
              dropdownMatchSelectWidth={false}
              onChange={handleCycleFilter}
              removeIcon={true}
            >
              {Object.values(cycleList).map((item) => (
                <Option value={item} label={item} title="" />
              ))}
            </Select>
          </div>
        </div>
      ),
      width: "14%",
      dataIndex: "appraisalCycle",
      key: "appraisalCycle",
      render: (val, row) => {
        return (
          <div
            style={{
              width: "6.5rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontSize: "1rem",
            }}
          >
            <div>{row?.appraisalCycle}</div>
            <div style={{ marginRight: "0.5rem" }}>
              <a
                type="primary"
                onClick={() => {
                  dispatch(adminActions.updateModalType("Appraisal"));
                  openCommonModal(row);
                }}
              >
                <img src={refreshIcon} style={{ width: "1.2rem" }} alt="" />
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "space-between",
            gap: "0.5rem",
            width: "100%",
          }}
        >
          <Popover content={content1}>
            <h4
              style={{
                margin: "0rem",
                color: "#696969",
                width: "500%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "1rem",
              }}
            >
              Appraisal Form Status
            </h4>
          </Popover>
          <div>
            <Select
              suffixIcon={
                <AiFillFilter
                  style={{
                    color:
                      formStatusFilter === "" ||
                      formStatusFilter === null ||
                      formStatusFilter === undefined
                        ? "#787b83"
                        : Color.modalblue,
                  }}
                />
              }
              allowClear
              trigger="click"
              type="text"
              placement="bottomLeft"
              bordered={false}
              dropdownMatchSelectWidth={false}
              onChange={handleFormStatusFilter}
              removeIcon={true}
            >
              {Object.values(statusList).map((item) => (
                <Option value={item} label={item} title="" />
              ))}
            </Select>
          </div>
        </div>
      ),
      width: "16.5%",
      dataIndex: "appraisalFormStatus",
      key: "appraisalFormStatus",
      render: (val, row) => {
        return (
          <div style={{ width: "8rem", fontSize: "1rem" }}>
            {row?.userStatus}
          </div>
        );
      },
    },

    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "space-between",
            gap: "0.5rem",
            width: "100%",
          }}
        >
          <Popover content={content2}>
            <h4
              style={{
                margin: "0rem",
                color: "#696969",
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "1rem",
              }}
            >
              Appraisal Status
            </h4>
          </Popover>

          <Select
            suffixIcon={
              <AiFillFilter
                style={{
                  color:
                    appraisalFilter === "" ||
                    appraisalFilter === null ||
                    appraisalFilter === undefined
                      ? "#787b83"
                      : Color.modalblue,
                }}
              />
            }
            allowClear
            trigger="click"
            type="text"
            placement="bottomLeft"
            bordered={false}
            dropdownMatchSelectWidth={false}
            onChange={handleAppraisalfilter}
            removeIcon={true}
          >
            {Object.values(appraisalOptions).map((item) => (
              <Option value={item} label={item} title="" />
            ))}
          </Select>
        </div>
      ),
      width: "15%",
      dataIndex: "appraisalStatus",
      key: "appraisalStatus",
      render: (val, row) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                width: "11rem",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              <Button
                type="text"
                style={{
                  background: row?.appraisalActive ? "#F2F9FF" : "#FEE4E4",
                  color: row?.appraisalActive ? Color.modalblue : Color.red,
                  borderColor: row?.appraisalActive
                    ? Color.modalblue
                    : Color.red,
                  width: "7rem",
                  fontSize: "1rem",
                }}
                onClick={() => onAppraisalStatusClick(row)}
              >
                {row?.appraisalActive ? "Active" : "Inactive"}
              </Button>
              <a style={{}}>
                <img
                  src={refreshIcon}
                  style={{ width: "1.2rem" }}
                  onClick={() => onAppraisalStatusClick(row)}
                />
              </a>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        dataSource={users}
        columns={columns}
        rowKey={(record) => record?.id}
        pagination={{
          position: "bottomRight",
          pageSize: meta?.itemsPerPage,
          size: "small",
          pageSizeOptions: [10, 30, 50, 100],
          current: Number(meta?.currentPage),
          total: meta?.totalItems ? meta?.totalItems : 0,
          onChange: (page, pageSize) => handlePageChange(page, pageSize),
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        className={classes.userTable}
        scroll={{
          y: "50.5vh",
        }}
      />

      <WorkSummaryModal />
      <CommonModal />
    </div>
  );
};

export default UserTable;
