/* eslint-disable react/display-name */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Api from "services/Api";
import { managerActions } from "./store";
import { Table, Button } from "antd";
import { Row, Col } from "antd";
import { globalActions } from "store/loader";

const AssociateList = () => {
  const dispatch = useDispatch();
  const { teamId } = useParams();

  const { associateList, selectedTeamId } = useSelector(
    (state) => state.manager
  );
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const { loader } = useSelector((state) => state.global);

  useEffect(() => {
    if (!selectedTeamId) {
      history.goBack();
      return;
    }
    dispatch(globalActions.updateLoader({ loader: true }));
    Api.get(
      "/manager/associate-list?teamId=" +
        selectedTeamId +
        "&managerId=" +
        user.id
    )
      .params({})
      .send((response, error) => {
        if (typeof response != "undefined") {
          dispatch(
            managerActions.updateAssociateList({
              associates: response.associates,
            })
          );
        }
      });
    return () => {
      // dispatch(
      //   managerActions.updateSelectedAssociateId({
      //     selectedAssociateId: null,
      //   })
      // );
    };
  }, []);

  const dataSource = associateList.map((item) => {
    return {
      key: item.user_id,
      id: item.user_id,
      name: item.user_name,
      managerStatus: item.workSummary_managerStatus,
      associateId: item.user_id,
      appraisalCycle: item.user_appraisalCycle,
    };
  });

  const onAssociateClick = (row) => {
    dispatch(
      managerActions.updateSelectedAssociateId({ selectedAssociateId: row.id })
    );
    history.push("/manager/appraisal-form");
  };

  const columns = [
    {
      title: "Associate Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (val, row) => (
        <Button
          onClick={() => onAssociateClick(row)}
          type="primary"
          disabled={row.managerStatus === "Completed" || row.appraisalCycle==="October"}
        >
          Submit Feedback
        </Button>
      ),
    },
  ];
  if (loader) {
    return null;
  }

  return (
    <>
      <br></br>
      <div className="p">
        <div className="V">
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </div>

        <div style={{ paddingTop: "10px", float: "right" }}>
          <Button
            type="primary"
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              background: "#1890ff",
              borderColor: "skyblue",
            }}
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </div>
      </div>
    </>
  );
};

export default AssociateList;
