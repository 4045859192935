import React from "react";
import classes from "./Header.module.css";
import { size } from "lodash";
import { Button, Dropdown, Menu, Row, Select, Space } from "antd";
import { AntDesignOutlined } from "@ant-design/icons";

import { Color } from "services/color";
import brigosha_logo from "assets/brigosha_logo.svg";
import faq_icon from "assets/faq_icon.svg";
import log_out_icon from "assets/log_out_icon.svg";
import logo_icon from "../../assets/Logo_Icon.svg";
import { authActions } from "pages/login/store";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineDown } from "react-icons/ai";
import "./index.css";
import { useHistory } from "react-router-dom";
import Manager_FAQ from "../../assets/Manager_FAQ.pdf";
import Associate_FAQ from "../../assets/Associate_FAQ.pdf";
import { useState } from "react";
import { adminActions } from "pages/admin/store";

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const {Option} = Select
  console.log("history", history, useHistory());
  const { token, user } = useSelector((state) => state.auth);

  const roles = ["Admin", "Manager"];

  const logoutHandler = () => {
    dispatch(authActions.removeUser());
  };

  const onChangeRole = (userRole) => {
    // console.log("User state changed into",{...user, role: userRole})
    dispatch(authActions.changerRole({ ...user, role: userRole }));
    userRole === "Manager" ? history.push("/manager") : history.push("/admin");
    window.location.reload(true);
  };

  const year = new Date().getFullYear();

  const onDowloadFAQ = () => {
    console.log("Clicking on Download", user?.role);
    if (user?.role === "Admin") {
      console.log("Download Admin FAQ");
      return;
    }
    if (user?.role === "Manager") {
      window.open(Manager_FAQ);
      console.log("Download Manager FAQ");
      return;
    }
    if (user?.role === "Associate") {
      window.open(Associate_FAQ);
      console.log("Download Associate FAQ");
      return;
    }
  };

  return (
    <>
      <div
        style={{ boxShadow: `0px 4px 5px ${Color.midGrey}` }}
        className={classes.header}
      >
        <img src={brigosha_logo} alt="" />
        {token && (
          <div style={{ marginRight: "3rem", display: "flex" }}>
            {user?.role !== "Admin" && (
              <Space>
                <Button
                  style={{
                    width: "10rem",
                    height: "1.9rem",
                    border: `1px solid ${Color.buttonColor}`,
                    background: Color.buttonColor,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    borderRadius: "3px",
                  }}
                  onClick={onDowloadFAQ}
                >
                  <img
                    style={{
                      width: "1rem",
                      height: "1rem",
                      margin: "0",
                      padding: "0",
                    }}
                    src={faq_icon}
                    alt=""
                  />
                  <h5
                    style={{
                      color: Color.white,
                      margin: "0.5rem",
                      fontFamily: "OpenSans-Regular",
                      fontSize: "0.875rem",
                    }}
                  >
                    Download FAQ's
                  </h5>
                </Button>
              </Space>
            )}

            <div style={{ marginLeft: "1rem" }} className="logout">
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item style={{ background: "none", padding: "0" }}>
                      <Button
                        ghost
                        style={{
                          width: "100%",
                          margin: "0",
                        }}
                        type="text"
                        onClick={logoutHandler}
                      >
                        <span
                          style={{
                            fontFamily: "Roboto-Bold",
                          }}
                        >
                          Logout
                        </span>
                      </Button>
                    </Menu.Item>
                  </Menu>
                }
              >
                <div
                  style={{
                    width: "10rem",
                    height: "2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "1rem",
                      height: "1rem",
                      margin: "0",
                      padding: "0",
                    }}
                    src={logo_icon}
                    alt=""
                  />
                  <h6
                    style={{
                      margin: "0 0 0 0.5rem ",
                      fontFamily: "Roboto-Bold",
                      fontSize: "1rem",
                    }}
                  >
                    {user?.name}
                  </h6>
                  <AiOutlineDown
                    style={{
                      color: Color.darkGrey,
                      margin: "0 0.5rem",
                    }}
                  />
                </div>
              </Dropdown>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          width: "89%",
          margin: "2rem 2rem 1rem 5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // border: "1px solid red",
        }}
      >
        <h2
          style={{
            fontSize: "1.9rem",
            fontFamily: "Roboto-Bold",
          }}
        >
          Appraisal for April {year}
        </h2>
        {user?.id === 3 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              // marginRight: "1rem",
              // border: "1px solid black",
            }}
          >
            <Select
              // options={roles}
              dropdownMatchSelectWidth={false}
              defaultValue={user?.role === "Admin" ? "Admin" : "Manager"}
              onChange={(value) => {
                onChangeRole(value);
              }}
            >
              {roles.map((item, index) => (
                <Select.Option key={index} label={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>

            {/* <Button
              onClick={() => {
                // setUserRole("Manager")
                onChangeRole("Manager");
              }}
              type={user?.role === "Manager" && "primary"}
              style={{ width: "8rem", height: "2.5rem", marginRight: "1rem" }}
            >
              Manager
            </Button>
            <Button
              type={user?.role === "Admin" && "primary"}
              onClick={() => {
                // setUserRole("Admin")
                onChangeRole("Admin");
              }}
              style={{ width: "8rem", height: "2.5rem" }}
            >
              Admin
            </Button> */}
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
