import React, { useState, useEffect } from "react";
//import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "../../index.css";
import { Form, Input, Button, Space } from "antd";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { associateActions } from "./store";
import Api from "services/Api";
import Notification from "services/Notification";
import { authActions } from "pages/login/store";
import { AssociateStatus } from "./associate-status";
import { globalActions } from "store/loader";

const WorkSummary = () => {
  const dispatch = useDispatch();
  const { workSummary, associate } = useSelector((state) => state.associate);
  const { user } = useSelector((state) => state.auth);
  const {loader} =useSelector((state) => state.global)

  useEffect(() => {
    dispatch(globalActions.updateLoader({loader:true}))
    Api.get("/associate/work-summary/?userId=" + user.id)
      .params({})
      .send((response, error) => {
        if (typeof response != "undefined") {
          dispatch(
            associateActions.updateWorkSummary({ workSummary: response })
          );
        }
      });
  }, []);

  const workSummaries = workSummary?.workSummary?.map((summary) => {
    return (
      <Row key={summary.id}>
        <Col span={12}>
          <Form.Item label="Manager's Name">
            <p>
              <b>{summary?.team?.manager?.name}</b>
            </p>
          </Form.Item>
        </Col>
        <Col span={10} offset={1}>
          <Form.Item label="Duration(months)">
            <p>
              <b>{summary?.workDuration}</b>
            </p>
          </Form.Item>
        </Col>
      </Row>
    );
  });

  const [form] = Form.useForm();

  const onDisagree = () => {
    const title = "Error";
    const text = "Please contact HR with the correct data";
    Notification.error(title, text);
  }

  const onFinish = (values) => {
    
    let title = "Error";
    if (!values?.experienceYear || !values?.experienceMonth) {
      if (!values?.experienceYear && !values?.experienceMonth) {
        let text = "Please fill experience";
        Notification.error(title, text);
      } else if (values?.experienceYear) {
        let text = "Please fill experience months";
        Notification.error(title, text);
      } else {
        let text = "Please fill experience Year";
        Notification.error(title, text);
      }
      return;
    }

    Api.post("/associate/work-summary/?userId=" + user.id)
      .params({ year: +values.experienceYear, months: +values.experienceMonth })
      .send((response, error) => {
        if (response !== "undefined" && response?.show?.type === "success") {
          dispatch(associateActions.updateAssociate({associate: {...associate, ...{userStatus: AssociateStatus.workSummaryAccepted}}}));
        }
      });
  };
  if(loader === true)
  {
    return null;
  }

  return (
    <>
      <div style={{
        // border:'1px solid black',
        paddingLeft:'5rem'}}>
        <h1>Work Summary</h1>{" "}
      </div>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div className="margin">
          <div className="head">
            <Row>
              <Col span={23}>
                <br></br>
                <p>
                  <b>{workSummary?.name}</b>
                </p>
              </Col>
            </Row>
          </div>
          <div className="container">
            <Row>
              <Col
                xs={23}
                sm={23}
                md={{ span: 11 }}
                lg={{ span: 11 }}
                xl={{ span: 11 }}
              >
                <Form.Item
                  label="Total professional experience in years"
                  name="experienceYear"
                >
                  <Input placeholder="" type="number" name="experienceYear" min={0} />
                </Form.Item>
              </Col>
              <Col
                xs={23}
                sm={23}
                md={{ span: 11, offset: 1 }}
                lg={{ span: 11 }}
                xl={{ span: 11, offset: 1 }}
              >
                <Form.Item
                  label="Total professional experience in months"
                  name="experienceMonth"
                >
                  <Input placeholder="" type="number"  name="experienceMonth" min={0}/>
                </Form.Item>
              </Col>
            </Row>
            <h2>Work Summary</h2>
            {workSummaries}
          </div>

          <div className="Button">
            <Row>
              <Col span={15} offset={5}></Col>
              <Space>
                <Col span={2} offset={0}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Next
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={2} offset={8}>
                  {" "}
                  <Form.Item>
                    {/* <Button type="" onClick={onDisagree}>
                      Disagree
                    </Button> */}
                  </Form.Item>
                </Col>
              </Space>
            </Row>
          </div>
        </div>
      </Form>
    </>
  );
};
export default WorkSummary;
