import React, { useState, useEffect } from "react";
import { Button, Col, Collapse, Popover, Row, Switch, Table } from "antd";
import { useHistory } from "react-router-dom";
import { adminActions } from "./store";
import { useDispatch, useSelector } from "react-redux";

import classes from "../styles/associateDetails.module.css";
import DownloadIcon from "../../assets/X Icon.svg";
import backIcon from "../../assets/backIcon.svg";
import editIcon from "../../assets/edit_icon.svg";
import { Color } from "services/color";
import { BiUser, AiOutlinePlusCircle } from "services/Icons";
import CommonModal from "./commonModal";
import Api from "services/Api";
import WorkSummaryModal from "./work-summary-modal";
import "../styles/index.css";
import FeedbackTable from "./FeedbackTable";

const AssociateDetails = (props) => {
  const [userDetails, setUserDetails] = useState("");
  const [selfFeedback, setSelfFeedback] = useState([]);
  const [managerData, setManagerData] = useState([]);
  const [managerFeedback, setManagerFeedback] = useState([]);
  const [feedbackForOrganization, setFeedbackForOrganization] = useState([]);
  const [appraisalSwitchLoading, setAppraisalSwitchLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const { Panel } = Collapse;
  const { state } = history?.location;
  const { refreshEffect } = useSelector((state) => state.admin);

  const bannerDataStyle = {
    fontSize: "1rem",
    fontFamily: "Roboto-Medium",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  };

  const subTitleStyleCol1 = {
    textOverflow: "ellipsis",
    width: "7rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
  };

  const subResponseStyleCol1 = {
    width: "15rem",
  };

  const subTitleStyleCol2 = {
    width: "11.5rem",
  };

  const subResponseStyleCol2 = {
    width: "13rem",
  };

  const parentDivForEven = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    marginTop: "1rem",
  };

  const lastChildrenDivForEven = { gridColumn: "1/-1" };

  const parentDivForOdd = {
    width: "100%",
    maxHeight: "100%",
    marginTop: "1.5rem",
    paddingBottom: "1rem",
    display: "flex",
    flexWrap: "wrap",
    gap: "1.95%",
    columnGap: "1.95%",
    overflow: "inherit",
  };

  const mySelfPhrase = "&downloadMyself=true";
  const managerPhrase = `&downloadMangerData=true`;
  const associateData = `&associate=true`;

  // useEffect(()=>{
  //   // if(history.action)
  //   console.log("hiiiiisssstttooorrryyy action",history)
  // },[history])

  useEffect(() => {
    const fetchData = () => {
      Api.get(`/admin/user/feedbacks?userId=${state}`).send(
        (response, error) => {
          if (typeof response !== "undefined") {
            dispatch(
              adminActions.updateUser_Cycle_Designation({
                Id: response?.user?.id,
                defaultCycle: response?.user?.appraisalCycle,
                defaultLevel: response?.user?.associateLevel,
              })
            );
            setUserDetails(response?.user);
            setSelfFeedback(response?.mySelfFeedback);
            setManagerData(response?.managerWiseFeedBack);
            setManagerFeedback(
              response?.user?.userFeedback[0]?.managerFeedback
            );
            setFeedbackForOrganization(
              response?.user?.userFeedback[0]?.companyFeedback
            );
            console.log("Associate Details", response);
          }
        }
      );

      Api.get(`/admin/users`)
        .params({})
        .send((response, error) => {
          if (typeof response !== "undefined") {
            dispatch(adminActions.updateCycle(response?.cycle));
            dispatch(adminActions.updateDesignation(response?.designation));
            dispatch(adminActions.updateStatus(response?.status));
            dispatch(adminActions.updateTeams(response?.teams));
          }
        });
    };
    fetchData();
  }, [refreshEffect]);

  const onDownloadClick = (value) => {
    console.log("Download Log", value);
    let phrase =
      value === "" || value === null || value === undefined ? "" : value;
    Api.get(`/admin/user/feedbacks?userId=${state}${phrase}`).send(
      (response, error) => {
        if (response) {
          window.open(`${response}`);
        }
        console.log("Download Log", response, error);
      }
    );
  };

  const onAppraisalStatusClick = (data) => {
    const appraisalActive = data.appraisalActive ? false : true;
    Api.patch("/admin/users/?userId=" + data.id)
      .params({ appraisalActive })
      .send((response, error) => {
        if (response !== "undefined" && response?.show?.type === "success") {
          dispatch(adminActions.updateSingleUser({ user: response.data }));
        }
      });
    setAppraisalSwitchLoading(false);
    dispatch(adminActions.updateRefreshEffect((olddata) => olddata + 1));
  };

  const feedbackFromManager = () => {
    let columns = [
      {
        title: (
          <h3
            style={{ fontWeight: "bold", fontSize: "1rem", textAlign: "left" }}
          >
            Questions
          </h3>
        ),
        dataIndex: "questions",
        key: "questions",
        width: "15rem",
        align: "left",
        render: (text, record) => (
          <div>
            <h5
              style={{
                fontFamily: "Roboto-BoldItalic",
                fontSize: "1rem",
                textAlign: "left",
              }}
            >
              {record?.questionKey}
            </h5>
            <h5 style={{ fontSize: "1rem" }}>{record?.value}</h5>
          </div>
        ),
      },
      {
        title: (
          <h3
            style={{ fontWeight: "bold", fontSize: "1rem", textAlign: "left" }}
          >
            Ratings
          </h3>
        ),
        key: "rating",
        width: "5rem",
        align: "left",
        render: (text, record) => (
          <h5 style={{ fontSize: "1rem" }}>
            {record?.rating ? record?.rating : "NA"}
          </h5>
        ),
      },
      {
        title: (
          <h3 style={{ fontWeight: "bold", textAlign: "left" }}>Remarks</h3>
        ),
        key: "remark",
        width: "15rem",
        align: "left",
        render: (text, record) => (
          <h5 style={{ fontSize: "1rem" }}>{record?.remark}</h5>
        ),
      },
    ];
    return columns;
  };

  

  const openCommonModal = (row) => {
    console.log("User Details", row);
    dispatch(
      adminActions.updateUser_Cycle_Designation({
        Id: row?.id,
        defaultCycle: row?.appraisalCycle,
        defaultLevel: row?.associateLevel,
      })
    );
    dispatch(adminActions.updateDesignationModal(true));
  };

  const onWorkSummaryClick = (data) => {
    dispatch(adminActions.updateWorkSummaryModal(true));
    dispatch(adminActions.updateRowData(data));
    console.log("Row data for workSummary", data);
  };

  return (
    <div style={{ margin: "1rem 5rem" }}>
      {/* Title */}
      <div className={classes.subhead}>
        <Button
          type="text"
          style={{
            margin: " 0 0.5rem 0.4rem 0",
            padding: "0",
          }}
          onClick={() => {
            dispatch(
              adminActions.updateUser_Cycle_Designation({
                ...{},
                defaultCycle: "",
                defaultLevel: "",
              })
            );
            dispatch(adminActions.updateSearchText(""));
            history.push({
              pathname: "/admin",
            });
          }}
        >
          <img src={backIcon} />
        </Button>
        <h2
          style={{
            fontSize: "1.5rem",
            fontFamily: "Roboto-Bold",
            marginTop: "0.5rem",
          }}
        >
          Associate&apos;s Details
        </h2>
        <a
          style={{
            margin: "0 0 0 0.5rem",
            textDecoration: "underline",
            textDecorationThickness: "1px",
            fontSize: "0.875rem",
            color: Color.black,
          }}
          onClick={() => {
            onDownloadClick(associateData);
          }}
        >
          Download Complete Data
        </a>
        <Button
          type="text"
          style={{ padding: "0", marginLeft: "0.4rem" }}
          onClick={() => {
            onDownloadClick(associateData);
          }}
        >
          <img
            style={{
              width: "1.2rem",
              height: "1.2rem",
            }}
            src={DownloadIcon}
            alt=""
          />
        </Button>
      </div>
      {/* Banner Starts here */}
      <div className={classes.banner}>
        <BiUser
          style={{
            fontSize: "9rem",
            margin: "0.6rem",
          }}
        />
        <div
          style={{
            width: "100%",
          }}
        >
          {/* User Details block starts here*/}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* First column starts here */}
            <div
              style={{
                width: "40%",
                paddingTop: "2rem",
                margin: "0 2rem",
              }}
            >
              <Popover content={userDetails?.name}>
                <h5
                  style={{
                    ...bannerDataStyle,
                    width: "8rem",
                    height: "2rem",
                    fontSize: "1rem",
                  }}
                >
                  {userDetails?.name}
                </h5>
              </Popover>
              <h5 style={{ color: Color.darkGrey, fontSize: "1rem" }}>
                {userDetails?.employeeId}
              </h5>
              <h5 style={bannerDataStyle}>{userDetails?.associateLevel}</h5>
            </div>

            {/* Second Column starts here */}
            <div
              style={{
                width: "100%",
                paddingTop: "1rem",
                flex: "1 1 90%",
              }}
            >
              <div
                style={{
                  display: "flex",
                }}
              >
                <div style={subTitleStyleCol1}>
                  <h5 style={{ fontSize: "1rem", color: Color.darkGrey }}>
                    Department:
                  </h5>
                </div>{" "}
                <div style={subResponseStyleCol1}>
                  <h5 style={{ fontSize: "1rem", color: Color.black }}>
                    {userDetails?.department}
                  </h5>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={subTitleStyleCol1}>
                  <h5 style={{ fontSize: "1rem", color: Color.darkGrey }}>
                    Location:
                  </h5>
                </div>{" "}
                <div style={subResponseStyleCol1}>
                  <h5 style={{ fontSize: "1rem", color: Color.black }}>
                    {userDetails?.officeLocation}
                  </h5>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <div style={subTitleStyleCol1}>
                  <h5
                    style={{
                      height: "1.5rem",
                      fontSize: "1rem",
                      color: Color.darkGrey,
                    }}
                  >
                    Email ID:
                  </h5>
                </div>{" "}
                <div style={subResponseStyleCol1}>
                  <Popover content={userDetails?.email}>
                    <h5
                      style={{
                        ...bannerDataStyle,
                        fontFamily: "Roboto-Regular",
                        color: Color.black,
                        width: "11rem",
                      }}
                    >
                      {userDetails?.email}
                    </h5>
                  </Popover>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={subTitleStyleCol1}>
                  <h5 style={{ fontSize: "1rem", color: Color.darkGrey }}>
                    Mobile:
                  </h5>
                </div>{" "}
                <div style={{ width: "50%", textAlign: "left" }}>
                  <h5
                    style={{
                      ...bannerDataStyle,
                      fontFamily: "Roboto-Regular",
                      color: Color.black,
                    }}
                  >
                    {userDetails?.mobile}
                  </h5>
                </div>
              </div>
            </div>
            {/* Second Column ends here */}

            {/* Third column starts here */}
            <div
              style={{
                width: "100%",
                paddingTop: "1rem",
                flex: "1 1 100%",
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={subTitleStyleCol2}>
                  <h5 style={{ fontSize: "1rem", color: Color.darkGrey }}>
                    Work Summary:
                  </h5>
                </div>{" "}
                <div style={subResponseStyleCol2}>
                  <Button
                    type="text"
                    style={{
                      height: "1.5rem",
                      padding: "0",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.3rem",
                    }}
                    onClick={() => onWorkSummaryClick(userDetails)}
                  >
                    <AiOutlinePlusCircle style={{ fontSize: "1rem" }} />
                    {userDetails?.workSummary?.length > 0 ? (
                      <h5
                        style={{
                          fontSize: "1rem",
                          marginTop: "0.6rem",
                          textDecoration: "underline",
                        }}
                      >
                        Change Summary
                      </h5>
                    ) : (
                      <h5
                        style={{
                          fontSize: "1rem",
                          marginTop: "0.6rem",
                          textDecoration: "underline",
                        }}
                      >
                        Add Summary
                      </h5>
                    )}
                  </Button>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={subTitleStyleCol2}>
                  <h5 style={{ fontSize: "1rem", color: Color.darkGrey }}>
                    Designation:
                  </h5>
                </div>{" "}
                <div style={subResponseStyleCol2}>
                  <Button
                    type="text"
                    style={{
                      height: "1.2rem",
                      display: "flex",
                      alignItems: "center",
                      padding: "0",
                      gap: "0.5rem",
                    }}
                    onClick={() => {
                      dispatch(adminActions.updateModalType("Designation"));
                      openCommonModal(userDetails);
                    }}
                  >
                    <h5
                      style={{
                        fontSize: "1rem",
                        color: Color.black,
                        marginTop: "0.5rem",
                      }}
                    >
                      {userDetails?.associateLevel}
                    </h5>
                    <img
                      style={{ width: "0.9rem", height: "0.9rem" }}
                      src={editIcon}
                    />
                  </Button>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={subTitleStyleCol2}>
                  <h5 style={{ fontSize: "1rem", color: Color.darkGrey }}>
                    Appraisal Cycle:
                  </h5>
                </div>{" "}
                <div style={subResponseStyleCol2}>
                  <Button
                    type="text"
                    style={{
                      height: "1.2rem",
                      display: "flex",
                      alignItems: "center",
                      padding: "0",
                      gap: "0.5rem",
                    }}
                    onClick={() => {
                      dispatch(adminActions.updateModalType("Appraisal"));
                      openCommonModal(userDetails);
                    }}
                  >
                    <h5
                      style={{
                        fontSize: "1rem",
                        color: Color.black,
                        marginTop: "0.5rem",
                      }}
                    >
                      {userDetails?.appraisalCycle}
                    </h5>
                    <img
                      style={{ width: "0.9rem", height: "0.9rem" }}
                      src={editIcon}
                    />
                  </Button>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={subTitleStyleCol2}>
                  <Popover content="Appraisal Form Status:">
                    <h5
                      style={{
                        ...bannerDataStyle,
                        maxWidth: "100%",
                        height: "2rem",
                        fontSize: "1rem",
                        fontFamily: "Roboto-regular",
                        color: Color.darkGrey,
                        // border: "1px solid black",
                      }}
                    >
                      Appraisal Form Status:
                    </h5>
                  </Popover>
                </div>{" "}
                <div style={subResponseStyleCol2}>
                  <Popover content={userDetails?.userStatus}>
                    <h5
                      style={{
                        ...bannerDataStyle,
                        maxWidth: "10rem",
                        height: "2rem",
                        fontSize: "1rem",
                        fontFamily: "Roboto-regular",
                        // border: "1px solid black",
                      }}
                    >
                      {userDetails?.userStatus}
                    </h5>
                  </Popover>
                </div>
              </div>
            </div>

            {/* Third column ends here */}

            {/* Fourth column starts here */}
            <div
              style={{
                paddingTop: "1rem",
                paddingRight: "1rem",
                flex: "1 1 70%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                }}
              >
                <h5
                  style={{
                    fontFamily: "Roboto-Regular",
                    fontSize: "1rem",
                    color: Color.darkGrey,
                    textAlign: "right",
                    marginRight: "0.5rem",
                  }}
                >
                  Appraisal Status:{" "}
                  <span
                    style={{
                      color: userDetails?.appraisalActive
                        ? Color.statusTrue
                        : Color.black,
                      fontFamily: "Roboto-Medium",
                    }}
                  >
                    {userDetails?.appraisalActive ? "Active" : "Inactive"}
                  </span>
                </h5>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Switch
                    style={{
                      backgroundColor: userDetails?.appraisalActive
                        ? Color.statusTrue
                        : Color.midGrey,
                      width: "5rem",
                      marginRight: "0.5rem",
                    }}
                    loading={appraisalSwitchLoading}
                    checked={userDetails?.appraisalActive}
                    checkedChildren={
                      <span style={{ color: Color.white, fontWeight: "700" }}>
                        Active
                      </span>
                    }
                    unCheckedChildren={
                      <span style={{ color: Color.black, fontWeight: "700" }}>
                        Inactive
                      </span>
                    }
                    onChange={() => {
                      setAppraisalSwitchLoading(true);
                      onAppraisalStatusClick(userDetails);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Fourth column ends here */}
          </div>
        </div>
        {/* User Details block */}
      </div>
      <div
        style={{
          background: Color.midGrey,
          width: "100%",
          height: "2px",
          marginTop: "2rem",
        }}
      ></div>
      <div
        style={{
          marginTop: "1rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
        }}
      >
        <h2
          style={{
            fontSize: "1.5rem",
            fontFamily: "Roboto-Bold",
            marginTop: "0.5rem",
          }}
        >
          Associate's Feedback
        </h2>
        <a
          style={{
            margin: "0 0 0 0.5rem",
            textDecoration: "underline",
            textDecorationThickness: "1px",
            fontSize: "0.875rem",
            color: Color.black,
          }}
          onClick={() => {
            onDownloadClick(mySelfPhrase);
          }}
        >
          Download Feedback Data
        </a>
        <Button
          type="text"
          style={{ padding: "0" }}
          onClick={() => {
            onDownloadClick(mySelfPhrase);
          }}
        >
          <img
            style={{
              width: "1.2rem",
              height: "1.2rem",
            }}
            src={DownloadIcon}
            alt=""
          />
        </Button>
      </div>
      <FeedbackTable sectionData={{
            title: "Feedback for themselves",
            data: selfFeedback,
          }}/>

      {/* {feedbackTable({
        title: "Feedback for themselves",
        data: selfFeedback,
      })} */}

      <div
        style={
          managerFeedback?.length % 2 === 0 ? parentDivForEven : parentDivForOdd
        }
      >
        {managerFeedback?.map((data) => (
          <div
            style={managerFeedback?.length % 2 === 0 ? {} : { width: "49%" }}
          >
            <FeedbackTable sectionData={{
            title: `Feedback for Manager - ${data?.managerName}`,
            data: data?.feedback,
          }}/>
            {/* {feedbackTable({
              title: `Feedback for Manager - ${data?.managerName}`,
              data: data?.feedback,
            })} */}
          </div>
        ))}

        <div
          style={
            managerFeedback?.length % 2 === 0
              ? lastChildrenDivForEven
              : { width: "49%" }
          }
        >
          <FeedbackTable sectionData={{
            title: "Feedback for Organization",
            data: feedbackForOrganization,
          }}/>
        </div>
      </div>
      {managerData?.map((manager, index) => (
        <div
          style={{
            paddingBottom: "3rem",
            maxHeight: "105vh",
          }}
          key={index}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <h2
              style={{
                fontSize: "2rem",
                fontFamily: "Roboto-Bold",
                margin: "1rem 0",
              }}
            >
              Manager&lsquo;s Feedback&nbsp;
              <span
                style={{
                  marginLeft: "1rem",
                  fontSize: "1rem",
                  fontFamily: "Roboto-Italic",
                  color: Color.managerNameColor,
                }}
              >
                {manager?.teamName}
              </span>
            </h2>
            {index === 0 && (
              <>
                <a
                  style={{
                    margin: "0.6rem 0 0 0.5rem",
                    textDecoration: "underline",
                    textDecorationThickness: "1px",
                    fontSize: "0.875rem",
                    color: Color.black,
                  }}
                  onClick={() => {
                    onDownloadClick(managerPhrase);
                  }}
                >
                  Download Feedback Data
                </a>
                <Button
                  type="text"
                  style={{ margin: "0.5rem 0 0 0 ", padding: "0" }}
                  onClick={() => {
                    onDownloadClick(managerPhrase);
                  }}
                >
                  <img
                    style={{
                      width: "1.2rem",
                      height: "1.2rem",
                    }}
                    src={DownloadIcon}
                    alt=""
                  />
                </Button>
              </>
            )}
          </div>
          <Collapse expandIconPosition="right" defaultActiveKey={["1"]}>
            <Panel
              header={
                <h3
                  style={{
                    fontSize: "1.188rem",
                    fontFamily: "Roboto-Bold",
                  }}
                >
                  {/* Feedback for themselves */}
                  Feedback for Associate from{" "}
                  <span
                    style={{
                      color: Color.managerNameColor,
                      fontFamily: "Roboto-Bold",
                      fontSize: "1.188rem",
                    }}
                  >
                    {manager?.teamManagerName}
                  </span>
                </h3>
              }
              key="1"
              className="customPanel"
            >
              <Row
                style={{
                  maxHeight: "77vh",
                  boxShadow: " 0px 3px 6px #00000014",
                }}
              >
                <Col style={{ width: "90%" }}>
                  <Table
                    columns={feedbackFromManager()?.map((col) => ({ ...col }))}
                    pagination={{
                      position: ["none"],
                      pageSize: manager?.mangersFeedBack?.length,
                    }}
                    className={classes.feedbackThemselvesTable}
                    dataSource={manager?.mangersFeedBack}
                    // dataSource={}
                    style={{
                      width: "100%",
                    }}
                    scroll={{
                      y: "65vh",
                    }}
                  />
                </Col>
                <Col
                  style={{
                    width: "10%",
                    maxHeight: "77vh",
                    display: "flex",
                    flexDirection: "column",
                    background: Color.lightGrey,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "0.5rem",
                      marginTop: "4.5rem",
                      // marginTop:'2rem',
                      // border:'1px solid black'
                    }}
                  >
                    <h4>Team Rank</h4>{" "}
                    <div
                      style={{
                        width: "10vh",
                        height: "10vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        background: Color.white,
                        boxShadow: "0px 2px 4px #0000000F",
                      }}
                    >
                      <h1
                        style={{
                          marginTop: "1rem",
                          fontSize: "2.25",
                          fontFamily: "Roboto-Bold",
                          color: Color.managerNameColor,
                        }}
                      >
                        {manager?.teamRank}
                      </h1>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "0.5rem",
                      marginTop: "2rem",
                    }}
                  >
                    <h4>Performance</h4>{" "}
                    <div
                      style={{
                        width: "10vh",
                        height: "10vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        background: Color.white,
                        boxShadow: "0px 2px 4px #0000000F",
                      }}
                    >
                      <h1
                        style={{
                          marginTop: "1rem",
                          fontSize: "2.25",
                          fontFamily: "Roboto-Bold",
                          color: Color.managerNameColor,
                        }}
                      >
                        {manager?.performance}
                      </h1>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "0.5rem",
                      marginTop: "2rem",
                    }}
                  >
                    <h4>Attitude</h4>{" "}
                    <div
                      style={{
                        width: "10vh",
                        height: "10vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        background: Color.white,
                        boxShadow: "0px 2px 4px #0000000F",
                      }}
                    >
                      <h1
                        style={{
                          marginTop: "1rem",
                          fontFamily: "Roboto-Bold",
                          fontSize: "2.25",
                          color: Color.managerNameColor,
                        }}
                      >
                        {manager?.attitude}
                      </h1>
                    </div>
                  </div>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
      ))}
      <WorkSummaryModal />
      <CommonModal />
    </div>
  );
};

export default AssociateDetails;
