import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ role, children, ...rest }) => {
  const { token, user } = useSelector((state) => state.auth);
  const isAllowed = user?.id !== 3 ? token && role === user.role : token ? true : false;
  // console.log("Entered in Manager",children)

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAllowed ? (
          children
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
