import {
  MinusCircleTwoTone,
  PlusCircleOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import { Button, Input, InputNumber, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Api from "services/Api";
import { adminActions } from "./store";
import { Color } from "services/color";

const WorkSummaryModal = () =>
  {
    const { teams, workSummaryModal, rowData } = useSelector(
      (state) => state.admin
    );

    const initialWorkSummary = rowData?.workSummary;

    let [workSummary, setWorkSummary] = useState(initialWorkSummary);
    let [deletedWorkSummary, setDeletedWorkSummary] = useState([]);
    const [deletedId, setDeletedId] = useState("");
    const [refresh, setRefresh] = useState(0);
    const [errorIndex, setErrorIndex] = useState(0);
    const [error, setError] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [durationError, setDurationError] = useState(false);
    const [teamNameError, setTeamNameError] = useState(false);
    const [errorType, setErrorType] = useState("");
    const [durationErrorType, setDurationErrorType] = useState("");
    const [teamErrorType, setTeamErrorType] = useState("");
    const [titleErrorType, setTitleErrorType] = useState("");

    const dispatch = useDispatch();
    // setWorkSummary(rowData?.workSummary)

    useEffect(() => {
      updateArray();
    }, [rowData?.workSummary, deletedWorkSummary]);

    console.log(
      "Work Summary Data",
      initialWorkSummary,
      workSummary,
      deletedWorkSummary
    );

    const options = teams.map((item, index) => {
      // console.log("Teams",item)
      return (
        <Select.Option
          key={index}
          label={`${item?.team_name} : ${item?.managername}`}
          title={`${item?.team_name} : ${item?.managername}`}
          value={item?.team_id}
        >
          {item?.team_name} : {item?.managername}
        </Select.Option>
      );
    });

    const updateArray = () => {
      setWorkSummary(rowData?.workSummary);
      setDeletedWorkSummary(deletedWorkSummary);
    };

    const handleOk = () => {
      let title = "please fill complete";
      let max_duration = 0;

      if (workSummary?.length === 0 && deletedWorkSummary?.length === 0) {
        console.log("Please add work Summary");
        setTitleError(true);
        setTitleErrorType("Please add work Summary");
        return;
      }

      if (
        deletedWorkSummary?.includes(undefined) &&
        workSummary?.length === 0
      ) {
        console.log("Please add work Summary");
        setTitleError(true);
        setTitleErrorType("Please add work Summary");
        return;
      }

      if (
        JSON.stringify(
          workSummary.find((element) => JSON.stringify(element) === "{}")
        ) === "{}"
      ) {
        console.log("please fill the details");
        setTitleError(true);
        setTitleErrorType("Please fill the details");
        return;
      }

      if (workSummary?.length > 0) {
        // console.log("Entered looping");
        const verifyObject = (objects) =>
          objects?.workDuration && objects?.team;

        // Below code is to validate duplicate teams
        for (let i = 0; i <= workSummary.length - 1; i++) {
          if(workSummary[i].workDuration >17){
            setTitleError(true);
            setTitleErrorType("Invalid Input! Work duration should be less than 18 Months.");
            return
          }
          for (let j = 0; j <= workSummary.length - 1; j++) {
            if (i != j) {
              if (workSummary[i]?.team?.id === workSummary[j]?.team?.id) {
                console.log("Duplicate Data", workSummary[i]?.team?.id, i);
                setTitleError(true);
                setTitleErrorType("Please select a different team!");
                return;
              }
            }
          }
        }

        // Below code is to find the sum of all the duration should be less than or equal to 18 months
        workSummary?.map((summary, index) => {
          if (summary?.workDuration) {
            max_duration += summary?.workDuration;
          }
        });

        if (max_duration > 17) {
          setDurationError(false);
          setTitleError(true);
          setTitleErrorType("Invalid input! Please add a valid work duration.");
          max_duration = 0;
          return;
        }

        // Below code is to find either duration or team exist or not in all the objects
        if (!workSummary?.every(verifyObject)) {
          workSummary?.map((summary, index) => {
            if (!summary?.workDuration || summary?.workDuration === null) {
              setErrorIndex(index);
              console.log("Please enter work duration", summary, errorIndex);
              setDurationError(true);
              setTeamNameError(false);
              setTitleError(false);
              setDurationErrorType("Please enter a vaild month duration!");
            }
            if (!summary?.team?.id) {
              setErrorIndex(index);
              console.log("Please select team", summary, errorIndex);
              setTeamNameError(true);
              setDurationError(false);
              setTitleError(false);
              setTeamErrorType("Please select team and manager name!");
            }
          });
          return;
        }
      }

      console.log("Work Summary added");

      setError(false);
      setTitleError(false);
      setDurationError(false);
      setTeamNameError(false);
      try {
        Api.patch("/admin/work-summary/?userId=" + rowData.id)
          .params({ workSummary, deletedWorkSummary })
          .send((response, error) => {
            if (
              response !== "undefined" &&
              response?.show?.type === "success"
            ) {
              setError(false);
              setDurationError(false);
              setTeamNameError(false);
              setTitleError(false);
              dispatch(
                adminActions.updateRefreshEffect((olddata) => olddata + 1)
              );
              onCancel();
            }
          });
      } catch (e) {
        // Do nothing
      }
    };

    const onCancel = () => {
      setError(false);
      setDurationError(false);
      setTeamNameError(false);
      setTitleError(false);
      setDeletedWorkSummary([]);

      dispatch(adminActions.updateWorkSummaryModal(false));
    };

    const onWorkDurationChange = (value, index) => {
      let newArray = [...workSummary];
      let newObject = { ...newArray[index] };
      newObject.workDuration = value;
      newArray[index] = newObject;
      setWorkSummary(newArray);
    };

    const onTeamChange = (value, index) => {
      let newArray = [...workSummary];
      let newObject = { ...newArray[index] };
      let selectedTeam = teams?.filter((item) => item.team_id === value);
      if (Object.keys(newObject).length === 0) {
        newObject = {
          team: {
            id: selectedTeam[0]?.team_id,
            name: selectedTeam[0]?.team_name,
            manager: { name: selectedTeam[0]?.managername },
          },
        };
      } else {
        newObject.team = {
          id: selectedTeam[0]?.team_id,
          name: selectedTeam[0]?.team_name,
          manager: { name: selectedTeam[0]?.managername },
        };
      }
      newArray[index] = newObject;
      setWorkSummary(newArray);
    };

    const deleteWorkSummary = (index) => {
      console.log("Deleting Item", index);
      let newArray = [...workSummary];
      let newRowData = { ...rowData };
      setDeletedWorkSummary([...deletedWorkSummary, newArray[index].id]);
      newRowData.workSummary = newArray.filter(
        (item) => newArray.indexOf(item) !== index
      );
      console.log("Filtered data", newRowData);
      dispatch(adminActions.updateRowData(newRowData));
      setError(false);
      setTeamNameError(false);
      setDurationError(false);
    };

    const addWorkSummary = () => {
      const newArray = [...workSummary, ...[{}]];
      setWorkSummary(newArray);
    };

    return (
      <Modal
        destroyOnClose={true}
        title={
          <div style={{ marginTop: "2rem" }}>
            <h1 style={{ fontFamily: "Roboto-Bold", fontSize: "1.5rem" }}>
              Work Summary
            </h1>
            <p style={{ fontFamily: "Roboto-Italic", fontSize: "1rem" }}>
              Summary of employee working in a team.
            </p>
            {titleError && (
              <div>
                {/* {index === workSummary.length - 1 && ( */}
                <h6 style={{ color: Color.red }}>{titleErrorType}</h6>
                {/* )} */}
              </div>
            )}
          </div>
        }
        visible={workSummaryModal}
        // onOk={handleOk}

        footer={null}
        onCancel={
          //   () => {
          //   dispatch(adminActions.updateWorkSummaryModal(false));
          // }
          onCancel
        }
      >
        {workSummary?.length > 0
          ? workSummary?.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: "flex-start",
                    fontSize: "16px",
                    flexDirection: "column",
                    // width:"25rem"
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <label> Month<span style={{color:Color.red}}>*</span></label>
                      <div>
                        <InputNumber
                          min={3}
                          value={item.workDuration}
                          onChange={(value) =>
                            onWorkDurationChange(value, index)
                          }
                          style={{ width: "12rem", marginBottom: "1.5rem" }}
                          placeholder="Please enter the number"
                        />
                        <div>
                          {durationError && (
                            <div>
                              {index === workSummary.length - 1 && (
                                <h6 style={{ color: Color.red }}>
                                  {durationErrorType}
                                </h6>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div style={{ marginLeft: "2rem" }}>
                      <label>Team & Manager<span style={{color:Color.red}}>*</span></label>
                      <div>
                        <Select
                          value={`${
                            item?.team?.name
                              ? `${item?.team?.name}: ${item?.team?.manager?.name}`
                              : "Select the Team"
                          }`}
                          placeholder="Select the Team"
                          style={{ width: "12rem", marginBottom: "1.5rem" }}
                          onChange={(value) => onTeamChange(value, index)}
                        >
                          {options}
                        </Select>
                        <div>
                          {teamNameError && (
                            <div>
                              {index === workSummary.length - 1 && (
                                <h6 style={{ color: Color.red }}>
                                  {teamErrorType}
                                </h6>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div style={{ marginLeft: "1.5rem", marginTop: "1.8rem" }}>
                      <a onClick={() => deleteWorkSummary(index)}>
                        <MinusCircleTwoTone twoToneColor="red" />
                      </a>
                    </div>
                  </div>
                </div>
              );
            })
          : null}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <a onClick={addWorkSummary} style={{ color: "black" }}>
            <PlusCircleTwoTone />
            {/* <PlusCircleOutlined /> */}
          </a>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <Button
            onClick={onCancel}
            style={{ width: "8rem", height: "2.5rem", marginRight: "2rem" }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={handleOk}
            style={{ width: "8rem", height: "2.5rem", marginRight: "1rem" }}
          >
            Save
          </Button>
        </div>
      </Modal>
    );
  };

export default WorkSummaryModal;
