import React, { Fragment, useEffect } from "react";
import "antd/dist/antd.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import Header from "components/Layout/Header";
// import Admin from "pages/admin/admin";
import UserTable from "pages/admin/user-table";
import Manager from "pages/manager/manager";
import AssociateHome from "pages/associate/associate-home";
import Login from "pages/login/Login";
import PrivateRoute from "components/PrivateRoute";
import { useSelector } from "react-redux";
import AssociateList from "pages/manager/associate-list";
import Api from "services/Api";
import ManagerAppraisalForm from "pages/manager/manager-appraisal-form";
import AssociateRank from "pages/manager/associate-rank";
import PerformanceMatrix from "pages/manager/performance-matrix";
import LoadingModal from "components/Loader";
import ManagerFeedbackForm from "pages/associate/manager-feedback-form";

import Routes from "components/routes";

function App() {
  const history = useHistory();
  // useEffect(() => {
  //   Api.get("/admin/users").params({}).send((response, error) => {
  //     console.log(response);

  //   });

  // }, [])

  // useEffect(()=>{
  //   history.push("/admin")
  // },[])


  return (
    <Router>
      <div>
        <Header />
        <Routes />
      </div>
    </Router>
  );
}

export default App;
