import React from "react";
import "antd/dist/antd.css";
import "../index.css";
import { Typography, Input, Select } from "antd";
import { Row, Col } from "antd";
const { Option } = Select;
const { Title } = Typography;

const FormInput = ({
  id,
  label,
  value,
  type,
  ratings,
  rating,
  comment,
  handleRatingChange,
  handleCommentChange,
}) => {
  let ratingEl;
  if (type === "rating") {
    const options = [];
    for (let i = 0; i < ratings?.length; i++) {
      options.push(
        <Option key={`option-${i}`} value={ratings?.[i]?.value}>
          {ratings?.[i]?.label}
        </Option>
      );
    }
    ratingEl = (
      <>
        <Row>
          <Col md={12} lg={6}>
            <Select
              placeholder="Select Rating"
              value={rating}
              onChange={(val) => handleRatingChange(val)}
              style={{ width: "100%" }}
            >
              {ratings.map((rating, index) => (
                <Option key={`option-${index}`} value={rating.value}>
                  {rating.label}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Col md={16} lg={9}>
            <Input.TextArea
              style={{ width: "100%" }}
              value={comment}
              onChange={(e) => handleCommentChange(e.target.value)}
              placeholder="Type minimum 15 characters"
            />
            <div style={{ color: "red", fontSize: "0.8em" }}>
              *Type minimum 15 characters
            </div>
          </Col>
        </Row>
      </>
    );
  }
  let remarkEl;
  if (type === "input") {
    remarkEl = (
      <Row>
        <Col md={16} lg={9}>
          <Input.TextArea
            value={comment}
            onChange={(e) => handleCommentChange(e.target.value)}
            placeholder="Type minimum 15 characters"
          />
          <div style={{ color: "red", fontSize: "0.8em" }}>
            *Minimum 15 characters needed
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Title level={3}>{label}</Title>
      <Title level={4} style={{ fontWeight: "normal" }}>
        {value}
      </Title>
      {ratingEl}
      {remarkEl}
    </>
  );
};
export default FormInput;
