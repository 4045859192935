import { Button, Col, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "services/Api";
import { adminActions } from "./store";
import UserTable from "./user-table";

import "../styles/index.css";
import DownloadIcon from "../../assets/X Icon.svg";
import SearchIcon from "../../assets/search icon.svg";

const Admin = () => {
  const {
    page,
    pageSize,
    refreshEffect,
    appraisalFilter,
    designationFilter,
    cycleFilter,
    formStatusFilter,
    sorting,
    emailSort,
    searchText,
  } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  // const [searchText, setSearchText] = useState("");
  const filtersList = [
    appraisalFilter,
    designationFilter,
    cycleFilter,
    formStatusFilter,
  ];
  const pageNumber =
    page !== "" || typeof page !== "undefined" ? `page=${page}` : "";
  const limit =
    pageSize !== "" || typeof pageSize !== "undefined"
      ? `&limit=${pageSize}`
      : "";
  const searchData =
    searchText === "" || typeof searchText === undefined
      ? ""
      : pageNumber === "" && limit === ""
      ? `search=${searchText}`
      : `&search=${searchText}`;

  const appraisalStatus =
    appraisalFilter === "" ||
    appraisalFilter === null ||
    appraisalFilter === undefined
      ? ""
      : `&appraisalStatus=${appraisalFilter}`;
  const desigFilter =
    designationFilter === "" ||
    designationFilter === null ||
    designationFilter === undefined
      ? ""
      : `&designation=${designationFilter}`;

  const cycFilter =
    cycleFilter === "" || cycleFilter === null || cycleFilter === undefined
      ? ""
      : `&appraisalCycle=${cycleFilter}`;
  const formstatusFilter =
    formStatusFilter === "" ||
    formStatusFilter === null ||
    formStatusFilter === undefined
      ? ""
      : `&status=${formStatusFilter}`;
  const sortingByName =
    sorting === "" || sorting === null || sorting === undefined
      ? ""
      : `&sort=${sorting}`;
  const sortingByEmail =
    emailSort === "" || emailSort === null || emailSort === undefined
      ? ""
      : `&emailsort=${emailSort}`;

  useEffect(() => {
    Api.get(
      `/admin/users?${pageNumber}${limit}${searchData}${appraisalStatus}${desigFilter}${cycFilter}${formstatusFilter}${sortingByName}${sortingByEmail}`
    )
      .params({})
      .send((response, error) => {
        if (typeof response !== "undefined") {
          dispatch(adminActions.updateUsers({ users: response.users.items }));
          dispatch(adminActions.updateMeta(response.users.meta));
          dispatch(adminActions.updateCycle(response.cycle));
          dispatch(adminActions.updateDesignation(response.designation));
          dispatch(adminActions.updateStatus(response.status));
          dispatch(adminActions.updateTeams(response.teams));
          console.log("All users", response);
        }
      });
  }, [refreshEffect, searchText]);

  return (
    <div style={{ margin: "1rem 5rem" }}>
      <Row
        className="row"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "25px",
        }}
      >
        <Col>
          <h4
            style={{
              fontSize: "1.5rem",
              fontFamily: "Roboto-Bold",
            }}
          >
            All Associates
          </h4>
        </Col>
        <Col>
          <div
            style={{
              width: "32.5rem",
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <a
              style={{
                fontSize: "0.875rem",
                color: "black",
                textDecoration: "underline",
                textDecorationThickness: "2px",
              }}
              onClick={() => {
                dispatch(adminActions.updateDesignationModal(true));
                dispatch(adminActions.updateModalType("AllUserDownload"));
              }}
            >
              Download User Data
            </a>
            <Button
              type="text"
              style={{ padding: "0", marginRight: "1rem" }}
              onClick={() => {
                dispatch(adminActions.updateDesignationModal(true));
                dispatch(adminActions.updateModalType("AllUserDownload"));
              }}
            >
              <img
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                }}
                src={DownloadIcon}
                alt=""
              />
            </Button>
            <Input
              style={{
                width: "20rem",
                height: "2rem",
              }}
              placeholder="Search"
              onChange={(e) => {
                console.log("Search Value", e.target.value);
                setTimeout(() => {
                  // setSearchText(e.target.value);
                  dispatch(adminActions.updateSearchText(e.target.value));
                }, 2000);
                dispatch(
                  adminActions.updatePage({ page: 1, pageSize: pageSize })
                );
              }}
              suffix={
                <Button
                  type="text"
                  style={{ padding: "0", margin: "0" }}
                  onClick={() => {
                    dispatch(
                      adminActions.updateRefreshEffect((olddata) => olddata + 1)
                    );
                  }}
                >
                  <img
                    style={{ width: "1rem", height: "1rem" }}
                    src={SearchIcon}
                  />
                </Button>
              }
            />
          </div>
        </Col>
      </Row>
      <UserTable />
    </div>
  );
};

export default Admin;
