import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "services/Api";
import { Table, Button, Select } from "antd";
import { managerActions } from "./store";
import { useHistory } from "react-router-dom";
import Notification from "services/Notification";
import { globalActions } from "store/loader";

const AssociateRank = () => {
  const { user } = useSelector((state) => state.auth);
  const { associateList, selectedTeamId } = useSelector(
    (state) => state.manager
  );
  const [ranks, setRanks] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader } = useSelector((state) => state.global);

  useEffect(() => {
    if (!selectedTeamId) {
      history.goBack();
      return;
    }
    dispatch(globalActions.updateLoader({ loader: true }));
    Api.get(
      "/manager/associate-list?teamId=" +
        selectedTeamId +
        "&managerId=" +
        user.id
    )
      .params({})
      .send((response, error) => {
        if (typeof response != "undefined") {
          dispatch(
            managerActions.updateAssociateList({
              associates: response.associates,
            })
          );

          setRanks(response.ranks);
        }
      });
  }, []);

  const onAssociateClick = (row, value) => {
    const conflictAssociate = associateList.filter(
      (associate) => associate.workSummary_teamRank === value
    );

    if (conflictAssociate.length > 0) {
      const changedAssociate = {
        user_id: conflictAssociate[0].user_id,
        workSummary_teamRank: null,
      };

      dispatch(
        managerActions.updateSingleAssociateRank({
          associate: changedAssociate,
        })
      );
    }

    // const newRank = ranks.filter(rank => {
    //     if (rank.value != value) {
    //         return rank;
    //     }
    // });
    // setRanks(newRank);

    const changedAssociate = {
      user_id: row.associateId,
      workSummary_teamRank: value,
    };

    dispatch(
      managerActions.updateSingleAssociateRank({ associate: changedAssociate })
    );
  };

  const submitHandler = () => {
    let title = "";
    for (const associate of associateList) {
      if (!associate.workSummary_teamRank) {
        let text = "Please fill all the ranks";
        Notification.error(title, text);
        return;
      }
    }
    Api.post("/manager/team-ranks?teamId=" + selectedTeamId)
      .params({ associateList })
      .send((response, error) => {
        if (typeof response != "undefined") {
          if (response?.show?.type === "success") {
            history.goBack();
          }
          //   dispatch(
          //     managerActions.updateAssociateList({
          //       associates: response.associates,
          //     })
          //   );
          //   setRanks(response.ranks);
        }
      });
  };

  const dataSource = associateList.map((item) => {
    return {
      key: item.user_id,
      name: item.user_name,
      managerStatus: item.workSummary_managerStatus,
      associateId: item.user_id,
      associateRank: item.workSummary_teamRank,
      appraisalCycle: item.user_appraisalCycle,
    };
  });

  const options = ranks.map((rank, index) => (
    <Select.Option key={index} value={rank.value}>
      {rank.label}
    </Select.Option>
  ));

  const columns = [
    {
      title: "Associate Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (val, row) => (
        <Select
          value={row.associateRank}
          style={{ width: 120 }}
          onChange={(value) => onAssociateClick(row, value)}
          disabled={
            // row.appraisalCycle === "October" ||
            row.managerStatus === "Completed"
          }
        >
          {options}
        </Select>
      ),
    },
  ];
  if (loader) {
    return null;
  }
  return (
    <>
      <br></br>
      <div className="contained">
        <div className="V">
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </div>
      </div>
      <div className="Button">
        <Button
          type="primary"
          style={{ background: "#1890ff", borderColor: "blue" }}
          onClick={submitHandler}
        >
          Submit
        </Button>
        <Button
          type="primary"
          style={{
            marginLeft: "10px",
            background: "#1890ff",
            borderColor: "blue",
          }}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </div>
    </>
  );
};

export default AssociateRank;
